<template>
  <div class="relative flex flex-col" ref="target" :class="{ 'h-9': border }">
    <div class="text-sm font-medium mb-1" v-if="label" :class="{'text-red-300': errorMessages.length > 0}">
      <slot name="label" :label="label">{{ label }}</slot>
    </div>
    <button :class="{ 'border-meclee-blue-500 border-2 h-full': border }"
      class="btn justify-between bg-white border-gray-200 hover:border-gray-300 text-gray-500 hover:text-gray-600 disabled:bg-slate-200 w-full"
      aria-label="Select date range" aria-haspopup="true" @click.prevent="isOpened = !isOpened" :aria-expanded="isOpened"
      :disabled="disabled">
      <span class="flex items-center">
        <span v-if="selectedLabel">{{ selectedLabel }}</span>
        <slot name="no-select-placeholder" v-else />
      </span>
      <svg class="shrink-0 ml-1 fill-current text-gray-400" :class="{ 'rotate-180': isOpened }" width="11" height="7"
        viewBox="0 0 11 7">
        <path d="M5.4 6.8L0 1.4 1.4 0l4 4 4-4 1.4 1.4z" />
      </svg>
    </button>
    <div class="text-xs mt-1 text-red-500" v-for="(message, i) in errorMessages" :key="`error-${props.name}-${i}`">
      {{ message }}
    </div>
    <div class="z-10 absolute top-full left-0 w-full bg-white border border-gray-200 py-1.5 rounded shadow-lg"
      v-show="isOpened">
      <div class="w-full p-3" v-if="searchable">
        <input class="form-input w-full" v-model="searchRequest" ref="searchInputEl" />
      </div>

      <div class="overflow-y-auto mt-1 max-h-44">
        <div class="font-medium text-sm text-gray-600" v-for="option in filteredOptions" :key="`option-${option.value}`">
          <button :type="props.buttonsType" tabindex="0"
            class="flex items-center w-full hover:bg-gray-50 py-1 px-3 cursor-pointer"
            :class="{ 'text-meclee-blue-500': selectedLabel === option.label }"
            @click="onItemClicked(option)" @focus="isOpened = true"
            @focusout="isOpened = false">
            <svg class="shrink-0 mr-2 fill-current text-meclee-blue-400" v-show="selectedLabel === option.label"
              width="12" height="9" viewBox="0 0 12 9">
              <path d="M10.28.28L3.989 6.575 1.695 4.28A1 1 0 00.28 5.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28.28z" />
            </svg>
            <span>{{ option.label }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';
const isOpened = ref(false);
const target = ref();
const searchInputEl = ref();

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  modelValue: String,
  label: {
    type: String,
  },
  errorMessages: {
    type: Array,
    default: [],
  },
  disabled: Boolean,
  searchable: Boolean,
  buttonsType: {
    type: String,
    default: "submit",
  },
  border: {
    type: Boolean
  }
});

const emit = defineEmits(['update:modelValue']);
const {t} = useI18n();
defineExpose({
  element: target,
});

const selectedLabel = computed(() => {
  const selectedOption = props.options.find(option => option.value === props.modelValue);
  return selectedOption ? selectedOption.label : undefined;
});

const searchRequest = ref();
const filteredOptions = computed(() => {
  return props.options.filter((item) => {
    if (props.searchable && searchRequest.value) {
      const foundByLabel = item.label.search(searchRequest.value) !== -1;
      const foundByValue = item.value.search(searchRequest.value) !== -1;
      return foundByLabel || foundByValue;
    } else {
      return item;
    }
  });
});

watch(isOpened, (currentValue) => {
  if (currentValue && props.searchable) {
    nextTick(() => {
      searchInputEl.value.focus();
    });
  }
})

onClickOutside(target, () => {
  isOpened.value = false;
  searchRequest.value = null;
})

function onItemClicked(option) {
  if (typeof option.click === 'function') {
    option.click();
  } else {
    emit('update:modelValue', option.value);
  }
  isOpened.value = false;
}
</script>

<style scoped></style>
